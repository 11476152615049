import { TextInputV2, TypographyV2 } from "components/v2"

import { Control, useController } from "react-hook-form"
import { ScreeningTemplateQuestion } from "@prisma/client"
import classNames from "classnames"
import { errorClassNamesV2 } from "components/v2/constants/styles"

interface MinutesQuestionCardProps {
    question: ScreeningTemplateQuestion
    control: Control<Record<string, string | (string | number)[]>>
    error: string | undefined
}

const MinutesQuestionCard = ({ question, control, error }: MinutesQuestionCardProps) => {
    const { question: question_text } = question
    const {
        field: { value, onChange }
    } = useController({
        name: `screeningAnswers.${question.id}`,
        control,
        rules: { required: true }
    })

    const handleChange = (value: string) => {
        let input = value.replace("m", "")
        if (input === "") {
            onChange("")
            return
        }

        if (!/^\d*$/.test(input)) return

        onChange(input + "m")
    }

    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <div className="w-full  p-[24px]">
                <TextInputV2
                    value={value as string}
                    onChange={handleChange}
                    className={classNames(
                        "mt-0 outline-none border-none bg-transparent text-lg focus:ring-0 text-center focus:border-none focus:shadow-[0_0_0_0_#000] shadow-none text-[44px] font-bold"
                    )}
                    placeholder={`0m`}
                />
            </div>
            {error && (
                <TypographyV2 type="xs" width="medium" className={errorClassNamesV2}>
                    {error}
                </TypographyV2>
            )}
        </div>
    )
}

export default MinutesQuestionCard
