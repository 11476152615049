import { ScreeningTemplateQuestion } from "@prisma/client"
import { TypographyV2, ControlledTimeInputV2 } from "components/v2"
import { Control } from "react-hook-form"
import { useCurrentPreferences } from "store/hooks"

interface ITimeOfDayQuestionCardProps {
    control: Control<Record<string, string | (string | number)[]>>
    error?: string
    question: ScreeningTemplateQuestion
}

const TimeOfDayQuestionCard = ({ control, error, question }: ITimeOfDayQuestionCardProps) => {
    const { is_required, question: question_text } = question
    const preferences = useCurrentPreferences()
    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <ControlledTimeInputV2
                name={`screeningAnswers.${question.id}`}
                classList={{ wrapper: "flex-1" }}
                control={control}
                error={error}
                isRequired={is_required}
            />
        </div>
    )
}

export default TimeOfDayQuestionCard
