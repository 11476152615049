import { SelectOptionsV2, TypographyV2 } from "components/v2"
import { Control, useController } from "react-hook-form"
import { ScreeningTemplateQuestion } from "@prisma/client"

interface MultipleChoiceQuestionCardProps {
    question: ScreeningTemplateQuestion
    control: Control<Record<string, string | (string | number)[]>>
    error: string | undefined
}

const MultipleChoiceQuestionCard = ({ question, control, error }: MultipleChoiceQuestionCardProps) => {
    const { is_required, question: question_text, definition } = question
    const options = (definition as any).options.map((option: string, i: number) =>
        option.trim() == "" ? (i + 1).toString() : option
    )

    const {
        field: { value, onChange }
    } = useController({
        name: `screeningAnswers.${question.id}`,
        control,
        rules: { required: is_required }
    })

    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <SelectOptionsV2 options={options} values={value} setValues={onChange} withOther={false} />
        </div>
    )
}

export default MultipleChoiceQuestionCard
